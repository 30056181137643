
import React from 'react';

import Layout from "../components/layout";
import { graphql } from "gatsby";
import RAView from "../components/views/playground/ra";

const IndexPage = ({ data }: any) => {

 

  return (<Layout meta={data.site.siteMetadata} title="Home" link={"/"} >
    <main style={{ height: "100%" }} className=" h-full ">
      <RAView />
    </main>
  </Layout>)
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;


export default IndexPage;